import words from 'lodash.words'

export const capitalize = <T extends string>(string: T) =>
  (string.charAt(0).toUpperCase() + string.slice(1)) as Capitalize<T>

export const capitalizeWords = (string: string) => words(string).reduce(
  (result, word, index) => result + (index ? ' ' : '') + capitalize(word),
  '',
)

export const removeExtension = (name: string) => {
  const parts = name.split('.')

  // Return just first element if no other parts
  if (parts.length === 1)
    return parts[0]

  // Return all but the last part joined
  return parts.slice(0, -1).join('.')
}

// Merge strings as a set. Eg. strSet(['a', 'a,b', 'b,c']) === 'a,b,c'
export const strSet = (vals: string[]) =>
  Array.from(new Set(vals.flatMap(x => x.split(',')))).join(',')
